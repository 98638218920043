body {

    & .btn {
        display: inline-block;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        user-select: none;
        border: 1px solid transparent;
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: .25rem;

        &.btn-primary {
            color: #fff;
            background-color: #007bff;
            border-color: #007bff;
        }
    }
}
