
.widget {
    border-radius: .5rem;
    background: #fcfcfc;
    padding: 1rem;

    & button,
    & .button {
        border-radius: .5rem;
        padding: .75rem 2rem;
        margin: 0;
        margin-bottom: 1.5rem;
        font-size: 1rem;
        font-weight: bold;
        position: relative;
        cursor: pointer;
        border: none;

        & img.left-icon,
        & svg {
            position: absolute;
            fill: #FFF;
            height: 1.5rem;
            max-width: 1rem;
            left: 1rem;
            transform: translate(0, -.25rem);
        }

        &.choose {
            background-color: #2e3740;
            border-color: #2e3740;
            color: #FFF;
        }

        &.submit {
            background: #34b2c0;
            border-color: #34b2c0;
            color: #FFF;
        }

        &.cancel {
            background-color: #ac4c8c;
            border-color: #ac4c8c;
            color: #FFF;
        }

        &.download {
            background: #85af5f;
            border-color: #85af5f;
            color: #FFF;
        }

        &.template {
            background: #34b2c0;
            border-color: #34b2c0;
            color: #FFF;
        }

        &.block {
            width: 100%;
        }
    }

    &,
    & button,
    & .button {
        box-shadow: rgba(0, 0, 0, 50%) 0px 2px 5px 0px;
    }

    & .inner-wrapper {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        height: 100%;

        & .column {
            display: block;
            flex: 1 1 auto;
            padding: 1rem;
            max-width: 100%;

            & hr.separator {
                display: block;
                width: 100%;
                color: #f2f2f2;
                border: 1px solid #f2f2f2;
                margin: 0;
                margin-bottom: 1rem;
            }

            &.flex-column,
            & .flex-column {
                display: flex;
                flex-flow: column nowrap;
            }

            &.flex-row,
            & .flex-row {
                display: flex;
                flex-flow: row nowrap;

                &.wrap {
                    flex-wrap: wrap
                }
            }

            & div.separator {
                flex: 0 0 1rem;
            }

            &.loader {
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;
            }

            &.small {
                flex: 0 1 25%;
                min-width: 250px;
            }
        }
    }
}
