.RegionTagPopup-container {
    background: #FFF;
    color: #000;
    z-index: 10;
    border: 2px solid #000;
    font-size: 1vmin;
    text-align: left;
    box-shadow: rgba(0, 0, 0, 50%) 0px 2px 5px 0px;
    width: auto;
    min-width: 9.5vmin;
    padding: .5vmin;

    & ul {
        list-style: none;
        padding: 0;
        margin: 0;

        & li {
            white-space: nowrap;
        }
    }

    &.teal {
        border: 2px solid #44a187;
    }

    &.light-blue {
        border: 2px solid #4882db;
    }

    &.dark-teal {
        border: 2px solid #476f88;
    }

    &.purple {
        border: 2px solid #574376;
    }

    &.yellow {
        border: 2px solid #b8aa5b;
    }

    &.majenta {
        border: 2px solid #a1448d;
    }

    &.dark-blue {
        border: 2px solid #5e48db;
    }

    &.brown {
        border: 2px solid #884f47;
    }

    &.green {
        border: 2px solid #456e40;
    }

    &.light-orange {
        border: 2px solid #b8805b;
    }
}
