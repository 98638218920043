.assets-page {
    padding: 1rem;
    width: 100%;

    & .widget {
        max-width: 1200px;
        margin: auto;

        & input[type=file] {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;

            &:focus {
                & + label {
                    outline: 1px dotted #000;
                    outline: -webkit-focus-ring-color auto 5px;
                }
            }

            &:focus + label,
            & + label:hover {
                background-color: #722040;
            }

            & + label svg {
                width: 1em;
                height: 1em;
                vertical-align: middle;
                fill: currentColor;
                margin-top: -0.25em;
                margin-right: 0.25em;
            }
        }

        & .checkbox-container {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            margin-bottom: 1.5rem;

            & .check-holder {
                flex: 0 1 1%;
                display: flex;
                align-items: center;
                height: calc(1.5em + .75rem + 2px);
                padding: .375rem .75rem;
                font-size: 1rem;
                font-weight: 700;
                color: #495057;
                text-align: center;
                white-space: nowrap;
                background-color: #e9ecef;
                border: 1px solid #ced4da;
                border-radius: .25rem;
                margin-right: -1px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            & label {
                display: block;
                height: calc(1.5em + .75rem + 2px);
                padding: .375rem .75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5 !important;
                color: #495057;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                border-radius: .25rem;
                transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                position: relative;
                flex: 1 0 50%;
                width: 1%;
                min-width: 0;
                margin-bottom: 0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        & button,
        & .button {
            text-align: center;
            border-radius: .5rem;
            padding: .75rem 2rem;
            margin: 0;
            margin-bottom: 1.5rem;
            font-size: 1rem;
            font-weight: bold;
            position: relative;
            cursor: pointer;
            border: none;

            & img.left-icon,
            & svg {
                position: absolute;
                fill: #FFF;
                height: 1.5rem;
                max-width: 1rem;
                left: 1rem;
                transform: translate(0, -.25rem);
            }

            &.cancel-button{
                margin-top: 7px;
            }

            &.table-button {
                margin-bottom: 0px;
                background-color: #F2F2F2;
                color: rgba(0, 0, 0, 0.87);
                fill: rgba(0, 0, 0, 0.87);
                transform: none;

                & p {
                    margin: 0;
                    text-align: right;
                    padding: 0;
                    font-weight: normal;
                    position: relative;
                    left: 0.5rem;
                }

                & svg {
                    fill: rgba(0, 0, 0, 0.87);
                    margin-top: 7px;
                }
            }

            &.left-button {
                margin-bottom: 0px;
                background-color: #F2F2F2;
                transform: none;

                & p {
                    margin: 0;
                    text-align: right;
                    padding: 0;
                    font-weight: normal;
                    position: relative;
                    left: 0.5rem;
                }

                & svg {
                    margin-top: 4px;
                }
            }

            &.choose {
                background-color: #2e3740;
                border-color: #2e3740;
                color: #FFF;
            }

            &.submit {
                left: 0rem;
                background: #34b2c0;
                border-color: #34b2c0;
                color: #FFF;
            }

            &.cancel {
                background-color: #ac4c8c;
                border-color: #ac4c8c;
                color: #FFF;
            }

            &.download {
                background: #85af5f;
                border-color: #85af5f;
                color: #FFF;
            }

            &.template {
                background: #34b2c0;
                border-color: #34b2c0;
                color: #FFF;
            }

            &.options {
                background: #85af5f;
                border-color: #85af5f;
                color: #FFF;
                justify-content: right;
            }

            &.block {
                width: 100%;
            }
        }

        &,
        & button,
        & .button {
            box-shadow: rgba(0, 0, 0, 50%) 0px 2px 5px 0px;
        }

        & .inner-wrapper {
            display: flex;
            flex-flow: row wrap;
            width: 100%;
            height: 100%;

            & .column {
                display: block;
                flex: 1 1 auto;
                padding: 1rem;
                max-width: 100%;

                & hr.separator {
                    display: block;
                    width: 100%;
                    color: #f2f2f2;
                    border: 1px solid #f2f2f2;
                    margin: 0;
                    margin-bottom: 1rem;
                }

                &.flex-column,
                & .flex-column {
                    display: flex;
                    flex-flow: column nowrap;
                }

                &.flex-row,
                & .flex-row {
                    display: flex;
                    flex-flow: row nowrap;

                    &.wrap {
                        flex-wrap: wrap
                    }
                }

                & div.separator {
                    flex: 0 0 1rem;
                }

                &.loader {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                }

                &.small {
                    flex: 0 1 25%;
                    min-width: 250px;
                }
            }
        }
    }
}
