.RegionView2D-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;

    & .bounds {
        position: relative;
        height: 100%;
        width: 100%;
        overflow: hidden;

        & .view {
            display: block;
            height: 0;
            padding-bottom: 0%;
            position: relative;

            & .inner {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 1px solid #000;
                background: #ccc;

                & .tag-image {
                    display: block;
                    height: 0;
                    width: 0;
                    border: 1px solid #000;
                    overflow: hidden;
                    position: absolute;
                    opacity: .5;
                }

                & .tag-zone {
                    display: block;
                    position: relative;
                    height: 0;
                    width: 0;
                    border: 1px solid #000;
                    overflow: hidden;

                    &.selected {
                        background: #c0e6f9;
                        background: linear-gradient(115deg, #4882db00 0%, #59ccf9ff 100%) !important;
                    }

                    &.loaded {
                        position: absolute;

                        & .zone-group-label {
                            display: block;
                            position: absolute;
                            max-width: 125px;
                            background: #ff008e !important;
                            color: #FFF;
                            font-size: 1.25vmin;
                            padding: .5rem .75rem;
                            bottom: 0;
                            right: 0;
                            font-weight: bold;
                        }

                        & header {
                            z-index: 9;
                            position: absolute;
                            top: 0;
                            left: 0;
                            background: #2a2b2f;
                            color: #FFF;
                            box-shadow: rgba(0, 0, 0, 50%) 2px 2px 5px -2px;
                            font-size: 1.5vmin;
                            padding: .5rem .75rem;
                            font-weight: bold;

                            &.teal {
                                background: #44a187;
                                background: linear-gradient(115deg, #44a187 0%, #54b7b4 100%);
                            }

                            &.light-blue {
                                background: #4882db;
                                background: linear-gradient(115deg, #4882db 0%, #59ccf9 100%);
                            }

                            &.dark-teal {
                                background: #476f88;
                                background: linear-gradient(115deg, #476f88 0%, #6298ba 100%);
                            }

                            &.purple {
                                background: #574376;
                                background: linear-gradient(115deg, #574376 0%, #8262b4 100%);
                            }

                            &.yellow {
                                background: #b8aa5b;
                                background: linear-gradient(115deg, #b8aa5b 0%, #d7cd86 100%);
                            }

                            &.majenta {
                                background: #a1448d;
                                background: linear-gradient(115deg, #a1448d 0%, #b7548c 100%);
                            }

                            &.dark-blue {
                                background: #5e48db;
                                background: linear-gradient(115deg, #5e48db 0%, #5978f9 100%);
                            }

                            &.brown {
                                background: #884f47;
                                background: linear-gradient(115deg, #884f47 0%, #ba7b62 100%);
                            }

                            &.green {
                                background: #456e40;
                                background: linear-gradient(115deg, #456e40 0%, #8ab462 100%);
                            }

                            &.light-orange {
                                background: #b8805b;
                                background: linear-gradient(115deg, #b8805b 0%, #e6c595 100%);
                            }

                            &.exit {
                                background: #ca0b00;
                                background: linear-gradient(115deg, #ca0b00 0%, #ca0b00 100%);
                            }
                        }
                    }
                }

                & .tag-dot {
                    position: absolute;
                    display: block;
                    width: 7.5%;
                    padding-bottom: 7.5%;
                    height: 0;
                    left: 0;
                    bottom: 0;
                    transition: left 1s linear, bottom 1s linear;
                    transform: translate(-50%, 50%);

                    &:hover {
                        transform: translate(-50%, 50%) scale(2);
                        z-index: 2010;
                    }

                    &.selected-tag {
                        z-index: 2000;
                    }

                    & > .content {
                        position: absolute;
                        width: 100%;
                        height: 100%;

                        & > .avatar-container {
                            z-index: 0;
                        }

                        & > .text {
                            position: absolute;
                            bottom: 2%;
                            width: 100%;
                            z-index: 1;

                            & > svg {
                                position: relative;
                                width: 100%;
                            }
                        }
                    }

                    & > .popup {
                        position: absolute;
                        bottom: 16px;
                        left: 16px;
                        z-index: 5000;
                    }
                }
            }
        }
    }
}
